<template>
    <Template
        v-bind="params"
        :is-loading="isLoading"
        :formated_address="formated_address"
        :places="state.google.placeResults"
        :site="site"
        @onSubmit="(list) => onSubmit(list)"
        @onChangeAddress="(address) => searchGooglePlaces(address)"
        @onClickAddress="(item) => onClickAddress(item)"
    />
</template>

<script>
import Template from "../components/templates/DemographicInformation/DemographicInformation.vue";
import { c } from "../components/constants";
import { Settings } from "../../settings";
export default {
    components: {
        Template,
    },
    data() {
        return {
            params: c.demographics,
            isLoading: false,
            site: null,
            formated_address: null,
        };
    },
    methods: {
        onClickAddress(site) {
            this.site = site;
            this.formated_address = site.formatted_address;
            console.log(this.site, this.formated_address, "===============");
            setTimeout(() => this.actions.google.emptyPlaceResults(), 1500);
        },
        async searchGooglePlaces(val) {
            try {
                if (val) {
                    await this.actions.google.searchGooglePlaces({
                        keyword: val,
                        types: ["political"],
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
        async onSubmit(list) {
            if (this.isLoading) return false;
            try {
                this.isLoading = true;
                // await this.actions.user.updateUser({
                //     employer: {
                //         details: this.site,
                //     },
                // });
                console.log(list, "list");
                const tags = [];
                const tagCreate = [],
                    tagUpsert = [];
                let gender = null,
                    ethnicity = [];
                let isSelfGender, ethencityDescribed;
                let ageGroup = {};

                // About how old are you?
                if (list[0].value && list[0].value.id) {
                    tags.push({ id: list[0].value.id });
                    if (list[0].value.name) {
                        const arr = list[0].value.name.split("-");
                        if (arr && arr.length > 1) {
                            ageGroup["lowerLimit"] = Number(arr[0]);
                            ageGroup["upperLimit"] = Number(arr[1]);
                        }
                    }
                }

                // How would you describe your gender
                if (list[1].value && list[1].value.id) {
                    if (list[1].value.name === "Self describe") {
                        isSelfGender = list[1].value;
                        tags.push({ id: list[1].value.id });
                    } else {
                        tags.push({ id: list[1].value.id });
                        if (
                            list[1].value?.name === "Male" ||
                            list[1]?.value?.name === "Female"
                        ) {
                            gender = list[1].value?.name?.toUpperCase();
                        }
                    }
                }

                // With which racial and ethic group(s) do you identify?
                if (list[2].value && list[2].value.length > 0) {
                    list[2].value.map((item) => {
                        if (item.name === "Self describe") {
                            ethencityDescribed = list[2].inputValue;
                        } else {
                            ethnicity?.push(item?.name);
                        }

                        tags.push({ id: item.id });
                    });
                }

                // In which city or state do you live in?
                for (let i = 0; i < list[3]?.value?.length; i++) {
                    const location = list[3]?.value?.[i];
                    const res = await this.actions.google.searchGooglePlaceById(
                        { placeId: location?.place_id }
                    );
                    console.log(res, "location detail");
                    var city;
                    var state;
                    var country;
                    res?.address_components?.map((addressComponent) => {
                        if (
                            _.indexOf(addressComponent.types, "neighborhood") >
                            -1
                        )
                            city = addressComponent["short_name"];
                        if (_.indexOf(addressComponent.types, "locality") > -1)
                            city = addressComponent["short_name"];
                        if (
                            _.indexOf(
                                addressComponent.types,
                                "administrative_area_level_1"
                            ) > -1
                        )
                            state = addressComponent["short_name"];
                        if (_.indexOf(addressComponent.types, "country") > -1)
                            country = addressComponent["short_name"];
                    });
                    console.log(city, state, country);
                    if (city) {
                        const item = this.state.tag.tags.find(
                            (t) => t?.name === city && !t?.isHidden
                        );
                        const update = {
                            type: "LOCATION",
                            category: "CITY",
                            name: city,
                            nameLower: city.toLowerCase(),
                            status: "APPROVED",
                            targetType: "ALL",
                        };
                        if (item?.id) {
                            tags.push({
                                id: item?.id,
                            });
                        } else {
                            tagCreate.push(update);
                        }
                    }
                    if (state) {
                        const item = this.state.tag.tags.find(
                            (t) => t?.name === state && !t?.isHidden
                        );
                        const update = {
                            type: "LOCATION",
                            category: "STATE",
                            name: state,
                            nameLower: state.toLowerCase(),
                            status: "APPROVED",
                            targetType: "ALL",
                        };
                        if (item?.id) {
                            tags.push({
                                id: item?.id,
                            });
                        } else {
                            tagCreate.push(update);
                        }
                    }
                    if (country) {
                        const item = this.state.tag.tags.find(
                            (t) => t?.name === country && !t?.isHidden
                        );
                        const update = {
                            type: "LOCATION",
                            category: "COUNTRY",
                            name: country,
                            nameLower: country.toLowerCase(),
                            status: "APPROVED",
                            targetType: "ALL",
                        };
                        if (item?.id) {
                            tags.push({
                                id: item?.id,
                            });
                        } else {
                            tagCreate.push(update);
                        }
                    }
                }

                // What is your level of household income?
                if (list[4].value && list[4].value.id) {
                    // What is your level of household income?
                    tags.push({ id: list[4].value.id });
                }

                const params = {
                    where: { id: this.state.currentUser?.id },
                    data: {
                        ...(isSelfGender && {
                            genderDescribed: list[1].inputValue,
                        }),
                        ...(ethencityDescribed && {
                            ethnicityDescribed: ethencityDescribed,
                        }),
                        gender: gender,
                        ethnicity: ethnicity?.join(", "),
                        tags: {
                            connect: tags,
                            create: tagCreate,
                        },
                    },
                };
                if (ageGroup && ageGroup.lowerLimit)
                    params.data["ageGroup"] = { create: ageGroup };
                console.log(params, "params data");
                await this.actions.user.saveUser(params);
                this.actions.alert.showSuccess({
                    message: "Updated demographic information successfully!",
                });
                this.$router.push("/interest-activity");
            } catch (e) {
                console.log(e, "error");
            } finally {
                this.isLoading = false;
            }
        },
    },
    async mounted() {
        await this.actions.tag.getTags({
            all: true,
            where: { status: "APPROVED" },
        });
        const ages = this.state.tag.tags.filter((t) => t.category === "AGE" && !t?.isHidden);
        const genders = this.state.tag.tags.filter(
            (t) => t.category === "GENDER" && !t?.isHidden
        );
        const ethnicitiesArr = this.state.tag.tags.filter(
            (t) => t.category === "ETHNICITY" && !t?.isHidden
        );
        const incomes = this.state.tag.tags.filter(
            (t) => t.category === "INCOME" && !t?.isHidden
        );
        console.log(ages, "ages");
        console.log("genders: ", genders);
        genders.push(genders.shift());
        console.log("genders1: ", genders);
        const ethnicities = ethnicitiesArr.filter(
            (ethencity) => ethencity.name !== "Self describe"
        );
        const selfDescribeEthencity = ethnicitiesArr.find(
            (ethencity) => ethencity.name === "Self describe"
        );
        if (selfDescribeEthencity) ethnicities.push(selfDescribeEthencity);
        this.params.items[3].options = [Settings.usObj];
        this.params.items[3].value = [Settings.usObj];
        this.params.items[0].options = ages;
        this.params.items[1].options = genders;
        this.params.items[2].options = ethnicities;
        this.params.items[4].options = incomes;
    },
};
</script>
